@use '@angular/material' as mat;
@use "sass:map";
@import 'variables';

@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';

@include mat.core();

$mbm-primary: mat.define-palette($primary-palette, default, lighter, darker);
$mbm-accent: mat.define-palette($accent-palette, default, lighter, darker);
$mbm-warn: mat.define-palette($danger-palette, default, lighter, darker);

$mbm-theme: mat.define-light-theme((
    color: (
        primary: $mbm-primary,
        accent: $mbm-accent,
    )
));

@include mat.all-component-themes($mbm-theme);

html, body {
    scroll-behavior: smooth;
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    color: $default-text;
}

a {
    text-decoration: none;
    color: map-get($mbm-primary, 500);
}

/* Utils */
.rounded {
    border-radius: 50%;
}

.blur {
    filter: blur(.4rem);
}

.hard-blur {
    filter: blur(1.2rem);
}

.pointer {
    cursor: pointer;
}

.cta-shadow {
    box-shadow: 0 3px 1px -2px rgba(227, 227, 227, 0.2),
        0 2px 2px 0 rgba(227, 227, 227, 14),
        0 1px 5px 0px rgba(227, 227, 227, 12) !important;
}

.hide {
    display: none;
}

.background {
    &-item {
        background: $default-background;
    }

    &-transparent {
        background: transparent;
    }

    &-primary {
        background: map-get($primary-palette, default);
        color: map.get($primary-palette, contrast, default);
    }

    &-accent {
        background: map-get($accent-palette, background);
        color: map.get($accent-palette, contrast, background);
    }

    &-warn {
        background: map-get($warn-palette, background);
        color: map.get($warn-palette, contrast, background);
    }

    &-danger {
        background: map-get($danger-palette, background);
        color: map.get($danger-palette, contrast, background);
    }

    &-success {
        background: map-get($success-palette, background);
        color: map.get($success-palette, contrast, background);
    }
}

.text {
    &-color {
        &-default {
            color: $default-text;
        }

        &-white {
            color: #ffffff;
        }

        &-primary {
            color: map-get($primary-palette, default);
        }

        &-accent {
            color: map-get($accent-palette, default);
        }

        &-warn {
            color: map-get($warn-palette, default);
        }

        &-danger {
            color: map-get($danger-palette, default);
        }

        &-success {
            color: map-get($success-palette, default);
        }
    }

    &-center {
        text-align: center;
    }

    &-upper {
        text-transform: uppercase;
    }

    &-italic {
        font-style: italic;
    }

    &-truncate {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &-bold {
        font-weight: bold !important;
    }

    &-semi-bold {
        font-weight: 500 !important;
    }

    &-light {
        font-weight: 300 !important;
    }

    &-semi-light {
        font-weight: 400 !important;
    }
}

.flex {
    display: flex;

    &-justify-center {
        justify-content: center;
    }
    &-justify-sa {
        justify-content: space-around;
    }
    &-justify-sb {
        justify-content: space-between;
    }
    &-justify-se {
        justify-content: space-evenly;
    }

    &-align-center {
        align-items: center;
    }

    &-dir-col {
        flex-direction: column;
    }

    &-gap-15 {
        gap: 15px;
    }
}

/* Margins & Paddings */
.m {
    &-t {
        &-50 {
            margin-top: 50px !important;
        }
    }
    &-b {
        &-0 {
            margin-bottom: 0!important;
        }
        &-8 {
            margin-bottom: 8px!important;
        }
    }
}

.divider {
    width: 20px;
    border-bottom: 1px solid black;
    margin-left: 10px;
}

/* Layout */
.default-page {
    padding: 40px 5% 25px;

    @media screen and (max-width: 425px) {
        padding-top: 20px;
    }
}

.text-title {
    @media screen and (max-width: 425px) {
        text-align: center;
    }
}

.learn-more {
    color: map-get($primary-palette, default);
    font-style: italic;
    cursor: pointer;
}

/* Cards */
.card-list {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    margin-top: 25px;

    &__item {
        width: calc(100% / 4 - 19px);

        @media screen and (min-width: 1920px) {
            width: calc(100% / 5 - 20px);
        }

        @media screen and (max-width: 1024px) {
            width: calc(100% / 3 - 17px);
        }

        @media screen and (max-width: 768px) {
            width: calc(100% / 2 - 13px);
        }

        @media screen and (max-width: 550px) {
            width: 100%;
        }
    }
}

.card {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 10px 0 #0000001a;

    &__header {
        display: flex;
        align-items: center;
        padding: 15px 15px 10px;

        &__cover {
            height: 75px;
            border-radius: 5px;
            margin-right: 10px;
        }

        &__main {
            display: flex;
            flex-direction: column;
            width: 100%;

            &__title-area {
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                width: 100%;

                &__title {
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    letter-spacing: 0.5px!important;
                    line-height: 20px!important;
                    margin-bottom: 8px!important;
                    padding-right: 10px;
                }

                &__appendix {
                    margin-left: 5px;
                    margin-bottom: 8px!important;
                    text-align: right;
                }
            }
        }
    }

    &__body {
        padding: 0 15px;
    }

    &__footer {
        display: flex;
        justify-content: center;
        gap: 10px;
        border-radius: 0 0 10px 10px;
        padding: 10px 15px;

        &--accent {
            background: map-get($accent-palette, background);
        }

        &--warn {
            background: map-get($warn-palette, background);
        }

        &--danger {
            background: map-get($danger-palette, background);
        }

        &--success {
            background: map-get($success-palette, background);
        }

        &__cta {
            height: 36px!important;
            line-height: 36px!important;
            text-transform: uppercase!important;
            background: #ffffff!important;
            box-shadow: 0 3px 1px -2px rgba(227, 227, 227, 0.2),
                0 2px 2px 0 rgba(227, 227, 227, 14),
                0 1px 5px 0px rgba(227, 227, 227, 12)!important;

            &--is-readonly {
                color: #bebebe;
                cursor: not-allowed!important;
            }
        }
    }
}

/* Mat Forms */
.mat-form-field {
    &-wrapper {
        margin-bottom: 20px;
    }

    &-appearance-fill .mat-form-field-flex {
        background-color: rgba(0, 0, 0, 0.02);
        border-radius: 10px 10px 0 0 !important;
    }

    &.search {
        ion-icon {
            font-size: 20px;
            margin-right: 10px;
            margin-bottom: -4px;
        }

        .mat-form-field-flex, .mat-form-field-infix {
            padding-top: 0;
        }
    }
}

.mat-checkbox-background, .mat-checkbox-frame {
    border-radius: 4px !important;
}

.search {
    width: 230px;

    @media screen and (max-width: 425px) {
        width: 200px;
    }

    .mat-form-field-wrapper {
        margin-bottom: 0!important;
    }
}

/* Mat Snackbar */
.mat-snack-bar-container {
    .mat-simple-snackbar-action {
        color: #ffffff;
    }

    &.background-accent, &.background-danger, &.background-warn, &.background-success {
        .mat-simple-snackbar-action {
            color: map.get($primary-palette, default)!important;
        }
    }
}

/* Swipper */
.swiper-button-prev, .swiper-button-next {
    color: #0f5d7a;
    background: #fbfbfb;
    border-radius: 50%;
    width: 50px;
    height: 50px;

    &::before, &::after {
        font-size: 25px;
    }
}

/* Arrow info */
.arrow-left-info {
    font-size: 0.8em!important;
    line-height: 28px!important;
    min-width: auto!important;

    &::after {
        content: "";
        position: absolute;
        right: 100%;
        top: 50%;
        height: 0;
        width: 0;
        border: 6px solid transparent;
        margin-top: -6px;
    }

    &.background-accent::after {
        border-right-color: map-get($accent-palette, background);
    }
    &.background-danger::after {
        border-right-color: map-get($danger-palette, background);
    }
    &.background-warn::after {
        border-right-color: map-get($warn-palette, background);
    }
    &.background-success::after {
        border-right-color: map-get($success-palette, background);
    }
}
