@use '@angular/material' as mat;

$default-text: #4B4B4B;
$default-background: #FBFBFB;

$primary-palette: (
    background: #eefafd,
    lighter: #5eb1d9,
    default: #0f5d7a,
    darker: #054a64,
    contrast: (
        background: $default-text,
        lighter: $default-text,
        default: white,
        darker: white,
    )
);

$accent-palette: (
    background: map-get($primary-palette, background),
    lighter: #82dcee,
    default: #0cd1e8,
    darker: #008593,
    contrast: (
        background: map-get($primary-palette, default),
        lighter: map-get($primary-palette, default),
        default: map-get($primary-palette, default),
        darker: map-get($primary-palette, default),
    )
);

$warn-palette: (
    background: #fff7eb,
    lighter: #efad7d,
    default: #e86d09,
    darker: #af5d00,
    contrast: (
        background: #e86d09,
        ligther: $default-text,
        default: white,
        darker: white,
    )
);

$danger-palette: (
    background: #FFF0F0,
    lighter: map-get(mat.$red-palette, 50),
    default: map-get(mat.$red-palette, 400),
    darker: map-get(mat.$red-palette, 800),
    contrast: (
        background: map-get(mat.$red-palette, 400),
        ligther: $default-text,
        default: white,
        darker: white,
    )
);

$success-palette: (
    background: #ECFDF3,
    lighter: #54dc8b,
    default: #0EC254,
    darker: #008f36,
    contrast: (
        background: #0EC254,
        ligther: $default-text,
        default: white,
        darker: white,
    )
);


$collections-palette: (
    own: map-get($primary-palette, default),
    ownBackground: map-get($accent-palette, background),
    read: map-get($primary-palette, default),
    readBackground: map-get($accent-palette, background),
    desire: map-get($primary-palette, default),
    desireBackground: map-get($accent-palette, background),
    favorite: #FF668E,
    favoriteBackground: #FFF3F6,
);
